(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name home.matches.controller:MatchesCtrl
   *
   * @description
   *
   */
  angular
    .module('neo.home.sancions')
    .controller('sancionsCtrl', sancionsCtrl);

  function sancionsCtrl(AclService,sancionscomite, $mdToast, $filter, $scope, $state) {
    var vm = this;
   vm.sancions=sancionscomite;
  for(var j=0;j<vm.sancions.length;j++){
    //  vm.sancions[j].sancion=vm.sancions[j].sancion.replace("dinamitzador","Tut@r de joc");
  }
  
$scope.exportToExcel= ((function() {
          var uri = 'data:application/vnd.ms-excel;base64,'
            , template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><meta http-equiv="content-type" content="application/vnd.ms-excel; charset=UTF-8"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>'
            , base64 = function(s) { return window.btoa(unescape(encodeURIComponent(s))) }
            , format = function(s, c) { return s.replace(/{(\w+)}/g, function(m, p) { return c[p]; }) }
          return function(table, name) {
            table = document.getElementById(table);
            
            var ctx = {worksheet: name || 'Report', table: table.innerHTML};
            window.location.href = uri + base64(format(template, ctx));
          }
        })());
  }
  
  
  
}());
